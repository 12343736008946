import { PERSONALIZED_ICONS } from '@Components/Common/Constents'
import _ from 'lodash'
import React from 'react'

const CodeComplexSection = ({ product }) => {
    return (
        <>
            <div className='home_fourth_section seen-on'>
                <div className='fourth_first_container'>
                    {/* <div className='tool-heading'>
                        <h2 className='color'>TOOL #2</h2>
                         </div>*/}
                    <h2 style={{ color: "#fff" }}>CODE Complex™</h2>
                    <h3>Nearly 400 million combinations possible.</h3>
                    <h3 className='formulation-data'>ONE FORMULATION FOR YOU.</h3>
                </div>
                <div className='fourth_second_container'>
                    <div className='image_and_icons_section'>
                        <div className='precision nutrition'>
                            <h3>Science-Driven, DNA-Based Precision Nutrition for Whole Body Health</h3>
                            <div className='list-genetics'>
                                <ul>
                                    <li>Made to order based on your genetics</li>
                                    <li>High-potency, bioactive ingredients</li>
                                    <li>Prebiotics + digestive enzymes</li>
                                    <li>Adaptogens and telomere support</li>
                                    <li>Non-GMO, gluten free</li>
                                    <li>3rd party tested</li>
                                    <li>Compounded in the USA</li>
                                </ul>
                            </div>
                        </div>
                        <div className='capsule'>
                            {product?.product_images?.length > 0 && <img src={process.env.API_URL + product?.product_images?.[0]?.image} />}
                            <div className='revolutionary-data'>
                                <div className='personalized_icons2'>
                                    {_.map(PERSONALIZED_ICONS, ({ icon, text }, index) => (
                                        <div key={index} className='iconContainer'>
                                            <span><img src={`/images/${icon}`} /></span>
                                            <p>{text}</p>
                                        </div>
                                    ))}
                                </div>
                                <p>CODE Complex™ is a revolutionary DNA-customized<br />nutritional supplement.</p>
                                <p className='boldOne'><b>Personalized biohacking simplified.</b></p>
                                <p>
                                    Containing key vitamins, minerals and micronutrients,<br />
                                    your CODE Complex™ formula gives your body<br />
                                    what your body needs.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CodeComplexSection